import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        redirect: '/home',

    },
    {
        path: '/home',
        component: () => import('@/views/home/hIndex'),
        hidden: true
    }
]

const createRouter = () => new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

const isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
router.beforeEach(async (to, from,next) => {
  if (isMobile) {
    location.href = 'https://gdapsy.com/MOB/mobile'
  } else {
    next()
  }
})

export default router
